'use strict';
import { useState } from 'react';

const PlusOneContainer = ({ plusOne, position, handleRemove, handleUpdate }) => {
  const [localPlusOne, setPlusOne] = useState(plusOne);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newPlusOne = {...localPlusOne, [name]: value};
    setPlusOne(newPlusOne);
    handleUpdate(newPlusOne, position);
  };

  return (
    <div className="guest-container p-3 mt-3">
      <div className="mt-1 text-end">
        <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={() => handleRemove(position)}></button>
      </div>
      <div>
        <label htmlFor={`plus-one-${position}-name`} className="form-label fs-5">Nombre</label>
        <input
          className="form-control fs-5"
          id={`plus-one-${position}-name`}
          required
          name="name"
          value={localPlusOne.name}
          onChange={handleInputChange}
        />
      </div>
      <div className="mt-3">
        <label htmlFor={`plus-one-${position}-allergies`} className="form-label fs-5">Alergias</label>
        <input
          className="form-control fs-5"
          id={`plus-one-${position}-allergies`}
          name="allergies"
          value={localPlusOne.allergies}
          onChange={handleInputChange}
        />
      </div>
      <div className="mt-3">
        <label htmlFor={`plus-one-${position}-vegetarian`} className="form-check-label fs-5">Vegetariano</label>
        <input
          type="checkbox"
          className="form-check-input fs-5"
          id={`plus-one-${position}-vegetarian`}
          name="vegetarian"
          checked={localPlusOne.vegetarian}
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
};

export default PlusOneContainer;
