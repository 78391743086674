'use strict';
import { createRoot } from 'react-dom/client';
import { useState, useEffect } from 'react';
import PlusOneContainer from './plusOneContainer';
import monograma from '../images/monograma.png';

const Rsvp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    allergies: "",
    vegetarian: false,
    plus_ones: []
  });
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false);

  useEffect(() => {
    if (hasFormSubmitted) {
      document.getElementById("thank-you").scrollIntoView({block: "center"});
    }
  }, [hasFormSubmitted]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setFormData({...formData, [name]: value});
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetch(`https://e9y885px14.execute-api.eu-west-2.amazonaws.com/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    }).then(
      response => response.json()
    ).then(json => {
      console.log(json);
      setHasFormSubmitted(true);
    });
  };

  const addPlusOne = (e) => {
    e.preventDefault();
    const newPlusOnes = [].concat(formData.plus_ones, { name: "", allergies: "", vegetarian: false });
    setFormData({
      ...formData,
      plus_ones: newPlusOnes
    });
  };

  const removePlusOne = (position) => {
    let newPlusOnes = [].concat(formData.plus_ones);
    newPlusOnes.splice(position, 1);

    setFormData({
      ...formData,
      plus_ones: newPlusOnes
    });
  };

  const updatePlusOnes = (plusOne, position) => {
    let newPlusOnes = [].concat(formData.plus_ones);
    newPlusOnes[position] = plusOne;

    setFormData({
      ...formData,
      plus_ones: newPlusOnes
    });
  };

  return (
    <>
      {!hasFormSubmitted &&
        <section className="form-section py-5">
          <article className="container text-center">
            <div className="row py-3 flex-column">
              <div className="col col-lg-6 offset-lg-3">
                <h2 className="fs-1">RSVP</h2>
                <p className="text-center fs-4">
                  Por favor, confirma tu asistencia antes del <strong>15/07/2023</strong>
                </p>
              </div>
              <div className="col col-lg-6 offset-lg-3 text-start">
                <form onSubmit={handleFormSubmit}>
                  <div className="mt-3">
                    <label htmlFor="name" className="form-label fs-5">Nombre</label>
                    <input
                      className="form-control fs-5"
                      id="name"
                      required
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="email" className="form-label fs-5">Correo</label>
                    <input
                      className="form-control fs-5"
                      id="email"
                      type="email"
                      required
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="allergies" className="form-label fs-5">Alergias</label>
                    <input
                      className="form-control fs-5"
                      id="allergies"
                      name="allergies"
                      value={formData.allergies}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="vegetarian" className="form-check-label fs-5">Vegetariano</label>
                    <input
                      type="checkbox"
                      className="form-check-input fs-5"
                      id="vegetarian"
                      name="vegetarian"
                      checked={formData.vegetarian}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mt-3">
                    <button className="btn btn-primary form-button fs-5 px-4" onClick={addPlusOne}><strong>+1</strong></button>
                  </div>
                  {formData.plus_ones.map((plusOne, index) =>
                    <PlusOneContainer
                      plusOne={plusOne}
                      position={index}
                      key={`plus-one-${index}`}
                      handleRemove={removePlusOne}
                      handleUpdate={updatePlusOnes}
                    />
                  )}
                  <div className="mt-3">
                    <button className="btn btn-primary fs-4 form-button w-100" type="submit"><strong>Enviar</strong></button>
                  </div>
                </form>
              </div>
            </div>
          </article>
        </section>
      }
      {hasFormSubmitted &&
        <section id="thank-you" className="thank-you-section my-2">
          <article className="container p-0 text-center">
            <div className="row py-3 flex-column">
              <div className="col">
                <h2 className="fs-2">¡Gracias por confirmar, te esperamos!</h2>
                <p className="fs-4">C&oacute;digo de vestimenta <strong>formal</strong></p>
                <picture>
                  <img src={monograma}/>
                </picture>
              </div>
            </div>
          </article>
        </section>
      }
    </>
  );
};

const root = createRoot(document.getElementById('rsvp-container'));
root.render(<Rsvp />);
